var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('BaseHeader',{attrs:{"title":"Biblioteca de mídias","navigation":_vm.navigation}}),_c('div',{staticClass:"container-table"},[(!_vm.loading)?_c('div',{staticClass:"box-total"},[_c('div',[_c('p',{staticClass:"total-vendas"},[_vm._v(" Total de mídias: "),_c('span',[_vm._v(_vm._s(_vm.total))])])]),_c('div')]):_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-skeleton',{attrs:{"height":"80px","width":"1200px"}})],1),(!_vm.loading)?_c('div',{staticClass:"header-table"},[_c('Uploader',{attrs:{"postAction":_vm.returnApi() + 'midia/upload'},on:{"update":_vm.fetchMidia}})],1):_c('div',{staticClass:"p-5"},[_c('div',{staticClass:"d-flex justify-content-center mt-5 mb-1"},[_c('b-skeleton',{attrs:{"height":"25px","width":"300px"}})],1),_c('div',{staticClass:"d-flex justify-content-center mt-1"},[_c('b-skeleton',{attrs:{"height":"10px","width":"20px"}})],1),_c('div',{staticClass:"d-flex justify-content-center mt-2"},[_c('b-skeleton',{attrs:{"height":"55px","width":"510px"}})],1)]),(!_vm.loading && _vm.midias.length > 0)?_c('div',{staticClass:"container-pesquisa"},[_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"inputSearch"},[_c('img',{staticClass:"Pointer searchIcon",attrs:{"src":require("@/assets/img/icons/search.svg")}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"input-busca",attrs:{"type":"text","placeholder":"Pesquisar itens de mídia"},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing)return;_vm.search=$event.target.value},_vm.debounce]}})])])]):_vm._e(),(!_vm.loading && _vm.midias.length > 0)?[_c('div',{staticClass:"paginas-conteudo-container"},_vm._l((_vm.midias),function(item,index){return _c('div',{key:index,staticClass:"paginas-conteudo"},[_c('div',{staticStyle:{"padding":"30px 30px","height":"230px"}},[(
                item.type === 'image/jpeg' ||
                item.type === 'image/webp' ||
                item.type === 'image/webp' ||
                item.type === '.webp' ||
                item.type === '.jpg' ||
                item.type === 'image/gif' ||
                item.type === 'image/svg'
              )?_c('img',{staticClass:"img-style pointer",attrs:{"src":item.url,"alt":item.name},on:{"click":function($event){return _vm.editarMidia(item)}}}):(item.type === 'application/pdf')?_c('iframe',{staticClass:"iframe-style pointer",attrs:{"scrolling":"no","frameborder":"0","src":item.url}}):_c('img',{staticClass:"img-conteudo img-style pointer",attrs:{"src":require("@/assets/img/icons/img_null.svg"),"alt":"imagem-vazia"},on:{"click":function($event){return _vm.editarMidia(item)}}})]),_c('div',{staticClass:"paginas-descricao"},[_c('div',{staticClass:"display-flex2"},[_c('h1',{staticClass:"paginas-descricao-titulo pointer",on:{"click":function($event){return _vm.editarMidia(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('b-dropdown',{staticStyle:{"margin-top":"-5px !important"},attrs:{"id":"dropPage","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/img/icons/pontos.svg"),"alt":"dots"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.editarMidia(item)}}},[_vm._v("Editar")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteMidia(item)}}},[_vm._v("Deletar")])],1)],1)])])}),0)]:_vm._e(),(!_vm.loading && _vm.midias.length === 0)?_c('b-row',{staticClass:"Table-body justify-content-center"},[_c('p',{staticClass:"nao-encontrado"},[_vm._v("Nenhuma mídia foi encontrada")])]):_vm._e(),_c('b-row',[_c('b-col',[(_vm.midias.length > 0)?_c('Paginate',{attrs:{"totalPages":_vm.pagination.totalPages,"activePage":_vm.pagination.currentPage,"disabled":true},on:{"to-page":_vm.toPage,"per-page":_vm.perPage}}):_vm._e()],1)],1),_c('EditarMidia',{attrs:{"item":_vm.itemEdit},on:{"edit-midia":function($event){return _vm.fetchMidia()}}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }