<template>
    <div>
        <ListaMidias></ListaMidias>
    </div>

</template>

<script>
import BaseView from '@/template/BaseView.vue';
import ListaMidias from '@/components/Midias/ListaMidias.vue';

export default {
  components: {
    BaseView,
    ListaMidias
  },
  // created() {
  //   if (this.$store.getters.user.user.level == 'seller') {this.$router.push("/dynamicRoute/AccessDenied")};
  // },
}
</script> 