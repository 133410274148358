<template>
  <BaseModal
    name="edit-midia"
    id="edit-midia"
    idModal="edit-midia"
    size="lg"
    title="Gestão de mídia"
  >
    <b-row v-if="!loading && item">
      <b-col cols="12">
        <div class="top-dados">
          <div>
            <p>Gerenciar os dados da mídia selecionada.</p>
          </div>
        </div>
        <div class="grid-edit">
          <div>
            <img
              class="img-style"
              v-if="
                item.type &&
                item.type === 'image/jpeg' ||
                item.type === 'image/png' ||
                item.type === 'image/webp' ||
                item.type === 'image/gif' ||
                item.type === '.gif' ||
                item.type === '.png' ||
                item.type === '.jpeg' ||
                item.type === '.webp'
              "
              :src="item.url"
              :alt="item.name"
            />
            <img
              v-else
              class="img-conteudo img-style"
              src="@/assets/img/icons/img_null.svg"
              alt="imagem-vazia"
            />
          </div>
          <div>
          <b-row class="mt-2">
              <b-col cols="12" md="12">
                <b-form-group label="Nome da mídia" label-for="name">
                  <b-form-input
                    id="name"
                    name="name"
                    v-model="item.name"
                    type="text"
                    placeholder="Escreva o nome"
                    v-validate="'required'"
                  ></b-form-input>
                  <b-form-invalid-feedback :state="!errors.has('item.post_title')">
                    Por favor, insira o nome
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" class="mt-3">
                <b-form-group label="URL da mídia" label-for="url_midia">
                  <b-form-input
                    id="url_midia"
                    name="url_midia"
                    v-model="item.url"
                    type="text"
                    placeholder="Link da mídia"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="12" class="mt-3">
                <b-form-group v-if="item.type" label="Tipo da mídia" label-for="type_midia">
                  <b-form-input
                    id="type_midia"
                    name="type_midia"
                    v-model="item.type"
                    type="text"
                    placeholder="Tipo da mídia"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>

    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Cancelar
      </BaseButton>
      <BaseButton variant="primary" @click="onSubmit" :disabled="loading">
        Salvar
      </BaseButton>
    </template>

    <div class="d-flex justify-content-center" v-if="loading">
      <b-spinner label="Loading..."></b-spinner>
    </div>
  </BaseModal>
</template>
<script>
import Multiselect from "vue-multiselect";

import MidiaService from "@/services/resources/MidiaService";
const serviceMidia = MidiaService.build();

export default {
  components: {
    Multiselect,
  },
  props: {
    item: Object,
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.item.post_title === "") {
            this.$grToast.toast("Obrigatório preencher o nome da mídia", {
              title: "Gerenciar Mídia",
              variant: "danger",
              autoHideDelay: 5000,
              appendToast: true,
            });
            this.loading = false;
            return;
          }

          var data = {
            id: this.item.id,
            name: this.item.name,
          }

          serviceMidia
            .update(data)
            .then(() => {
              this.$grToast.toast("Mídia editada com sucesso", {
                title: "Mídia",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
              this.$emit("edit-midia");
              this.$bvModal.hide("edit-midia");
            })
            .catch(() => {
              this.$grToast.toast("Erro ao editar mídia", {
                title: "Mídia",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
        }
      });
    },
  },
};
</script>

<style scoped>
p {
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  color: #81858e;
  line-height: 1.5;
}
p + p {
  margin-top: 5px;
}
.grid-edit {
  display: grid;
  grid-template-columns: 3fr 2fr;
  gap: 50px;
  margin-top: 30px;
}

.img-style {
  height: 400px;
  width: 100%;
  word-break: break-all;
  object-fit: contain;
  border-radius: 10px;
}
</style>
