var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseModal',{attrs:{"name":"edit-midia","id":"edit-midia","idModal":"edit-midia","size":"lg","title":"Gestão de mídia"},scopedSlots:_vm._u([{key:"footer",fn:function({ cancel }){return [_c('BaseButton',{staticClass:"mr-4",attrs:{"variant":"link-info"},on:{"click":cancel}},[_vm._v(" Cancelar ")]),_c('BaseButton',{attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.onSubmit}},[_vm._v(" Salvar ")])]}}])},[(!_vm.loading && _vm.item)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"top-dados"},[_c('div',[_c('p',[_vm._v("Gerenciar os dados da mídia selecionada.")])])]),_c('div',{staticClass:"grid-edit"},[_c('div',[(
              _vm.item.type &&
              _vm.item.type === 'image/jpeg' ||
              _vm.item.type === 'image/png' ||
              _vm.item.type === 'image/webp' ||
              _vm.item.type === 'image/gif' ||
              _vm.item.type === '.gif' ||
              _vm.item.type === '.png' ||
              _vm.item.type === '.jpeg' ||
              _vm.item.type === '.webp'
            )?_c('img',{staticClass:"img-style",attrs:{"src":_vm.item.url,"alt":_vm.item.name}}):_c('img',{staticClass:"img-conteudo img-style",attrs:{"src":require("@/assets/img/icons/img_null.svg"),"alt":"imagem-vazia"}})]),_c('div',[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Nome da mídia","label-for":"name"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"id":"name","name":"name","type":"text","placeholder":"Escreva o nome"},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}}),_c('b-form-invalid-feedback',{attrs:{"state":!_vm.errors.has('item.post_title')}},[_vm._v(" Por favor, insira o nome ")])],1)],1),_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"URL da mídia","label-for":"url_midia"}},[_c('b-form-input',{attrs:{"id":"url_midia","name":"url_midia","type":"text","placeholder":"Link da mídia","disabled":""},model:{value:(_vm.item.url),callback:function ($$v) {_vm.$set(_vm.item, "url", $$v)},expression:"item.url"}})],1)],1),_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12","md":"12"}},[(_vm.item.type)?_c('b-form-group',{attrs:{"label":"Tipo da mídia","label-for":"type_midia"}},[_c('b-form-input',{attrs:{"id":"type_midia","name":"type_midia","type":"text","placeholder":"Tipo da mídia","disabled":""},model:{value:(_vm.item.type),callback:function ($$v) {_vm.$set(_vm.item, "type", $$v)},expression:"item.type"}})],1):_vm._e()],1)],1)],1)])])],1):_vm._e(),(_vm.loading)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }