<template>
  <div class="container">
    <BaseHeader title="Biblioteca de mídias" :navigation="navigation">
    </BaseHeader>

    <div class="container-table">
      <div class="box-total" v-if="!loading">
        <div>
          <p class="total-vendas">
            Total de mídias:
            <span>{{ total }}</span>
          </p>
        </div>
        <div></div>
      </div>

      <div v-else class="d-flex justify-content-center">
        <b-skeleton height="80px" width="1200px"></b-skeleton>
      </div>

      <div class="header-table" v-if="!loading">
        <Uploader
          :postAction="returnApi() + 'midia/upload'"
          @update="fetchMidia"
        />
      </div>

      <div v-else class="p-5">
        <div class="d-flex justify-content-center mt-5 mb-1">
          <b-skeleton height="25px" width="300px"></b-skeleton>
        </div>
        <div class="d-flex justify-content-center mt-1">
          <b-skeleton height="10px" width="20px"></b-skeleton>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <b-skeleton height="55px" width="510px"></b-skeleton>
        </div>
      </div>

      <div class="container-pesquisa" v-if="!loading && midias.length > 0">
        <div style="position: relative">
          <!-- <h4 class="text-filter">Pesquisar mídia</h4> -->
          <div class="inputSearch">
            <img
              src="@/assets/img/icons/search.svg"
              class="Pointer searchIcon"
            />
            <input
              type="text"
              v-model="search"
              @input="debounce"
              placeholder="Pesquisar itens de mídia"
              class="input-busca"
            />
          </div>
        </div>
      </div>


      <template v-if="!loading && midias.length > 0">
        <div class="paginas-conteudo-container">
          <div
            class="paginas-conteudo"
            v-for="(item, index) in midias"
            :key="index"
          >
            <div style="padding: 30px 30px; height: 230px">
              <img
                class="img-style pointer"
                @click="editarMidia(item)"
                v-if="
                  item.type === 'image/jpeg' ||
                  item.type === 'image/webp' ||
                  item.type === 'image/webp' ||
                  item.type === '.webp' ||
                  item.type === '.jpg' ||
                  item.type === 'image/gif' ||
                  item.type === 'image/svg'
                "
                :src="item.url"
                :alt="item.name"
              />
              <iframe
                scrolling="no"
                frameborder="0"
                class="iframe-style pointer"
                v-else-if="item.type === 'application/pdf'"
                :src="item.url"
              ></iframe>
              <img
                v-else
                @click="editarMidia(item)"
                class="img-conteudo img-style pointer"
                src="@/assets/img/icons/img_null.svg"
                alt="imagem-vazia"
              />
            </div>
            <div class="paginas-descricao">
              <div class="display-flex2">
                <h1
                  @click="editarMidia(item)"
                  class="paginas-descricao-titulo pointer"
                >
                  {{ item.name }}
                </h1>
                <b-dropdown
                  id="dropPage"
                  right
                  style="margin-top: -5px !important"
                >
                  <template #button-content>
                    <img src="@/assets/img/icons/pontos.svg" alt="dots" />
                  </template>
                  <b-dropdown-item @click="editarMidia(item)"
                    >Editar</b-dropdown-item
                  >
                  <b-dropdown-item @click="deleteMidia(item)"
                    >Deletar</b-dropdown-item
                  >
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
      </template>

      <b-row
        v-if="!loading && midias.length === 0"
        class="Table-body justify-content-center"
      >
        <p class="nao-encontrado">Nenhuma mídia foi encontrada</p>
      </b-row>
      <b-row>
        <b-col>
          <Paginate
            v-if="midias.length > 0"
            :totalPages="pagination.totalPages"
            :activePage="pagination.currentPage"
            @to-page="toPage"
            @per-page="perPage"
            :disabled="true"
          />
        </b-col>
      </b-row>

      <!-- <div
        v-if="loading"
        class="Table-body py-4 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="dark" label="Spinning"></b-spinner>
      </div>  -->

      <!-- END BODY -->

      <!-- modals -->
      <EditarMidia @edit-midia="fetchMidia()" :item="itemEdit" />
    </div>
  </div>
</template>
<script>
import _ from "lodash";

import Vue from "vue";

import MidiaListaService from "@/services/resources/MidiaListaService";
import MidiaService from "@/services/resources/MidiaService";

import Paginate from "@/components/Paginate";
import BaseHeader from "@/components/BaseHeader";
import Uploader from "@/components/Midias/Uploader";
import EditarMidia from "@/components/Midias/EditarMidia";

const serviceMidiaLista = MidiaListaService.build();
const serviceMidia = MidiaService.build();

export default {
  name: "ListaMidias",
  components: {
    Uploader,
    Paginate,
    BaseHeader,
    EditarMidia,
  },
  data() {
    return {
      navigation: [{ link: "Biblioteca de mídias", to: this.$route.fullPath }],
      itemEdit: null,
      midias: [],
      search: null,
      loading: true,
      totalPages: 0,
      total: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      client: {
        width: 0,
      },
    };
  },
  methods: {
    debounce: _.debounce(function () {
      this.pagination.currentPage = 1;
      this.fetchMidia(this.search);
    }, 500),
    returnApi() {
      return process.env.VUE_APP_API_HOST;
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    editarMidia(item) {
      this.itemEdit = JSON.parse(JSON.stringify(item));
      this.$bvModal.show("edit-midia");
    },
    deleteMidia(item) {
      Vue.swal({
        title: "Deletar mídia",
        text: `Deseja deletar a mídia: ${item.name}?`,
        type: "danger",
        showCancelButton: true,
        confirmButtonText: "Deletar",
        cancelButtonText: "Cancelar",
        customClass: "sweet-container",
        confirmButtonClass: "button button-danger mt-3 mb-3",
        cancelButtonClass: "button button-link-info mr-4 mt-3 mb-3",
        buttonsStyling: false,
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          var data = {
            id: `${item.id}`,
          };
          serviceMidia
            .destroy(data)
            .then(() => {
              this.$grToast.toast("Mídia deletada com sucesso", {
                title: "Mídia",
                variant: "info",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .catch(() => {
              this.$grToast.toast("Erro ao deletar mídia", {
                title: "Mídia",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.fetchMidia();
            });
        }
      });
    },
    fetchMidia(search = null) {
      this.loading = true;
      this.pagination.totalPages = 1;
      let data = {
        page: this.pagination.currentPage,
      };
      if (this.search) {
        data.search = this.search;
      }
      serviceMidiaLista
        .search(data)
        .then((response) => {
          this.midias = response.data;
          this.total = response.total;
          this.pagination.totalPages = response.last_page;
          this.pagination.currentPage = response.current_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchMidia(this.search);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchMidia(this.search);
    },
  },
  created() {
    if(this.$store.getters.user.user.level == 'seller' || this.$store.getters.user.user.level == 'gerent_seller'){
      this.$router.push("/dynamicRoute/AccessDenied");
    }
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  mounted() {
    this.loading = true;
    this.fetchMidia();
  },
  computed: {
    isMobile() {
      return this.client.width <= 768;
    },
  },
};
</script>

<style lang="scss" scoped>
.inputSearch {
  padding-bottom: 30px;
  position: relative;
  input {
    position: relative;
  }
}
.input-busca {
  width: 100%;
  height: 45px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
  border: 1px solid #ededf0;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.searchIcon {
  position: absolute;
  left: 18px;
  bottom: 50px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
.text-filter {
  font-size: 12px;
}
.container-pesquisa {
  display: flex;
  align-items: center;
  display: grid;
  justify-content: flex-end;
}
.paginas-menu {
  border-radius: 10px;
  border: none;
}
.margin {
  height: 60px !important;
}
input {
  margin-bottom: 0 !important;
}
.paginas-descricao-opt {
  color: var(--greenn);
}
.paginas-descricao-titulo {
  font-weight: 600;
  font-size: 16px;
  color: var(--gray01);
  word-break: break-word;
}
.paginas-descricao {
  padding: 30px 30px;
}
.paginas-conteudo-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.paginas-conteudo {
  border: 1px solid #ededf0;
  border-radius: 10px;
}
.img-conteudo {
  width: 408px;
  height: 217.42px;
  border-radius: 5px 5px 0 0;
}
.img-icon {
  width: 12px;
  height: 13.5px;
}
.display-flex2 {
  display: grid;
  grid-template-columns: 1fr 80px;
}
.display-flex {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.nova-pagina {
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  padding: 20px 40px;
  gap: 10px;
  background: var(--greenn);
  border-radius: 10px;
  border: none;
}
.box-total {
  justify-content: center;
}
.metas {
  list-style: none;
  font-size: 11px;

  li {
    word-break: break-all;
  }
}
.btn-table {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: rgba(237, 237, 240, 0.5);
  outline: none;
}
.btn-table:active {
  background: rgba(237, 237, 240, 0.5);
  border: none;
}
.btn-table img {
  filter: invert(50%);
}
.btn-table:first-child {
  background: var(--greenn2);
  margin-right: 15px;
}
.btn-table:first-child:active {
  background: var(--greenn2);
  border: none;
}
.btn-table:first-child img {
  filter: invert(0);
}
.btn-table:nth-child(3) {
  margin-left: 15px;
  margin-right: 20px;
}
.header-table {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 30px 0;
  padding-top: 30px;
}
.flex-sale {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.datas .vue-daterange-picker {
  width: 175px;
  font-size: 14px !important;
  color: #81858e !important;
}
.reset-data {
  font-size: 14px;
  color: #81858e;
  font-weight: normal;
  margin-left: 15px;
}
.input-busca {
  width: 100%;
  height: 50px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.search {
  position: absolute;
  left: 37px;
  top: 22px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
.container-pesquisa {
  position: relative;
}
@media screen and (max-width: 1300px) {
  .search {
    left: 30px;
  }
}
.container-pesquisa {
  text-align: right;
  z-index: 1;
}
.btn-estatistica {
  margin-left: -30px;
}

.grid-report {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
}
@media screen and (max-width: 768px) {
  .searchIcon {
    bottom: 32px;
  }
  .inputSearch {
    padding-bottom: 10px;
  }
  .container-pesquisa {
    justify-content: center;
  }
  .header-table {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 10px;
  }
  .flex-sale {
    display: block;
  }
  .flex-sale div {
    margin-left: 0;
  }
  .flex-acoes {
    margin: 15px 0;
  }
  .container-pesquisa {
    text-align: left;
    margin-bottom: 10px;
  }
  .search {
    left: 20px;
  }
  .input-busca {
    width: 100%;
  }
  .btn-estatistica {
    margin-left: 0;
    margin-bottom: 20px;
  }

  .grid-report {
    grid-template-columns: 1fr 1fr;
  }
  .paginas-conteudo-container {
    grid-template-columns: 1fr;
  }
  .nova-pagina {
    margin: 5px;
  }
}
.dados-cliente div {
  margin-bottom: 3px;
}
.dados-cliente div:nth-child(2) {
  font-weight: normal;
  font-size: 13px;
}

.offer-name {
  font-weight: normal !important;
  font-size: 13px !important;
}

.whats-cliente {
  font-size: 13px;
  color: var(--greenn);
}
.whats-cliente svg {
  margin-right: 10px;
}
.valor-venda {
  margin-left: 10px;
}

#id_venda {
  position: relative;
}
#id_venda::before {
  content: "";
  width: 15px;
  height: 15px;
  background: transparent;
  border-radius: 50%;
  display: block;
  position: absolute;
  left: -25px;
  top: 0;
}
.TWO_CREDIT_CARDS {
  position: absolute;
  left: 5px;
  top: -5px;
}

// tipo data
#type {
  margin-bottom: 0 !important;
  margin-left: 10px;
}
.Table-body {
  padding: 15px;
}
.Table-body::before {
  left: 0;
}
.inverted--black {
  filter: invert(100);
}
.title-report {
  color: var(--gray01);
  font-weight: bold;
  font-size: 20px;
}
.title-report-margin-top {
  margin-top: 41px;
}
.title-report-margin-bottom {
  margin-top: 41px;
}

.default {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: #000;
  background: #0000003b;
  border-radius: 3px;
  // white-space: nowrap;
}

.success {
  height: 19px;
  padding: 5px;
  overflow-x: hidden;
  font-weight: 600;
  font-size: 10px;
  color: var(--greenn);
  background: var(--greenn) 3b;
  border-radius: 3px;
  // white-space: nowrap;
}

div#checkbox-group-all {
  position: relative;
  left: -5px;
}

.img-style {
  height: 217px;
  object-fit: contain;
  width: 100%;
  word-break: break-all;
}

.iframe-style {
  height: 217px;
  overflow: hidden;
  pointer-events: none;
}

.pointer {
  cursor: pointer;
}
</style>
